import styled from 'styled-components'
import { typography, color, Media } from '@cgtalks/common/theme'

export const Navigation = styled.div`
  width: 100%;
  height: 50px;
  background: #2e2f3a;
  ${Media.lessThan(Media.small)} {
    height: 40px;
  }
`
export const Category = styled.div`
  max-width: 1400px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: auto;
  ${Media.lessThan(Media.small)} {
    justify-content: space-around;
  }
`
export const CategoryItem = styled.div`
  width: 180px;
  background: ${(p) => (p.active ? '#21212A' : '')};
  border-radius: 10px 10px 0px 0px;
  font-size: ${typography.text};
  font-weight: 500;
  color: ${(p) => (p.active ? '#00FFAB' : `${color.white}`)};
  text-align: center;
  line-height: 46px;
  cursor: pointer;
  &:hover {
    background: #21212a;
  }
  ${Media.lessThan(Media.small)} {
    width: 100px;
    line-height: 37px;
    border-radius: 5px 5px 0 0;
  }
`

export const OfflineContent = styled.div`
  color: ${color.white};
  max-width: 1400px;
  margin: auto;
  ${Media.lessThan(Media.small)} {
    margin: auto;
    width: 90.66vw;
  }
`
export const OfflineTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 42px auto 33px;
  line-height: 24px;
  ::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 24px;
    background: #00ffab;
    border-radius: 3px;
  }
  ${Media.lessThan(Media.small)} {
    margin: 38px auto 15px;
  }
`
export const Headline = styled.div`
  position: relative;
  margin-left: 20px;
  font-size: ${typography.h3};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`

export const OfflineShareContent = styled.div`
  display: flex;
  max-width: 1400px;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    display: block;
  }
`
export const ShareOfflineContentCard = styled.div`
  margin-bottom: 30px;
`
export const ShareCgOfflineEmptyContainer = styled.div`
  display: flex;
  padding-top: 115px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 920px;
  padding-bottom: 140px;
  img {
    height: 200px;
    margin-bottom: 28px;
  }
  p {
    color: #7b7b8b;
    font-size: ${typography.textSmall};
    margin: 0 0 15px 0;
  }
  ${Media.lessThan(Media.small)} {
    padding-top: 58px;
    padding-bottom: 70px;
    width: 100%;
    img {
      height: 100px;
      margin-bottom: 14px;
    }
    p {
      font-size: ${typography.textThin};
      margin-bottom: 8px;
    }
  }
`

export const RegistrationPoster = styled.a`
  width: 440px;
  height: 360px;
  background: #2b2b37;
  border-radius: 10px;
  margin-bottom: 26px;
  display: block;
  ${Media.lessThan(Media.small)} {
    width: 90.66vw;
    height: 74.13vw;
    border-radius: 5px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
`
export const RenderPoster = styled.a`
  width: 440px;
  height: 580px;
  background: #2b2b37;
  border-radius: 10px;
  display: block;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: 90.66vw;
    height: 119.5vw;
    border-radius: 5px;
    margin-bottom: 75px;
    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const PaginationWrapper = styled.div`
  margin: 40px 0 60px;
  justify-content: flex-start;
`
