import React from 'react'
import TitleBgIcon from '../../images/main/title-bg.png'
import ProfileCircleIcon from '../../images/main/profile-circle.png'
import { Swiper } from '@cgtalks/common/components'
import { LiveStatusIcon } from '@cgtalks/common/components/icon'
import { LiveStatus, LiveStatusMap } from '@cgtalks/common/constant/type'
import { navigate } from 'gatsby'
import {
  IconTitleWrapper,
  IconTitleImg,
  IconTitleText,
  ShareOnlineContentCard,
  ShareOnlineCardStatus,
  LiveButton,
  GuestProfileWrapper,
  OfflineContentCard,
  ShareOfflineContent,
  MoreButton,
} from './atoms'

export const IconTitle = ({ text, imgMbWidth, icon = TitleBgIcon }) => {
  return (
    <IconTitleWrapper style={{ position: 'relative' }} imgMbWidth={imgMbWidth}>
      <IconTitleImg src={icon} alt='' />
      <IconTitleText>{text}</IconTitleText>
    </IconTitleWrapper>
  )
}

export const SwiperSwitch = ({ children, ...rest }) => {
  const onlineActivity = React.Children.map(children, (child) => {
    if (child) {
      return child
    }
  })
  return <Swiper {...rest}>{onlineActivity}</Swiper>
}

export const OnlineShareCard = ({ info }) => {
  const { title, status, period, image, slug } = info
  return (
    <ShareOnlineContentCard>
      <ShareOnlineCardStatus color={LiveStatusMap.get(status)?.color}>
        <LiveStatusIcon
          fill={LiveStatusMap.get(status)?.bgColor}
          viewBox='0 0 92 30'
          preserveAspectRatio='xMinYMid'
        />
        <span>{LiveStatusMap.get(status)?.text || '往期推荐'}</span>
      </ShareOnlineCardStatus>
      {image?.length && (
        <img src={image[0]} alt={title} onClick={() => navigate(`/sharecg/${slug}.html`)} />
      )}
      <div className='online-share-title'>{period}</div>
      <div className='online-share-content'>
        <div>{title}</div>
        <LiveButton onClick={() => navigate(`/sharecg/${slug}.html`)}>
          {status === LiveStatus.LIVE ? '进入直播' : '了解更多'}
        </LiveButton>
      </div>
    </ShareOnlineContentCard>
  )
}

export const GuestProfile = ({ info }) => {
  const { avatar, name, position } = info
  return (
    <GuestProfileWrapper className='guest-profile'>
      <div className='img-wrapper'>
        <img src={ProfileCircleIcon} alt='' />
        <img src={avatar[0]} className='avatar' alt='' />
      </div>
      <div className='name'>{name}</div>
      <div className='position'>{position}</div>
    </GuestProfileWrapper>
  )
}

export const OfflineShareCard = ({ info }) => {
  const { title, image, address, slug, specificTime } = info
  return (
    <OfflineContentCard>
      {image && image.length && <img src={image[0]} alt={title} />}
      <ShareOfflineContent>
        <div className='offline-share-title'>{title}</div>
        <div className='offline-share-time'>{specificTime}</div>
        <div className='offline-share-address'>{address}</div>
        <MoreButton onClick={() => navigate(`/cgactivity/${slug}.html`)}>了解更多</MoreButton>
      </ShareOfflineContent>
    </OfflineContentCard>
  )
}
