import { Media, mbSize } from '@cgtalks/common/theme'
import styled from 'styled-components'

export const BannerContainer = styled.div`
  position: relative;
  height: 520px;
  display: flex;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(1000)};
  }
`

export const MainBannerTip = styled.h1`
  font-size: 72px;
  font-weight: bold;
  color: #ffffff;
  margin: auto;

  ${Media.lessThan(Media.small)} {
    /* font-size: ${mbSize(72)}; */
    margin: auto 19.2vw;
    text-align: center;
    font-size: 36px;
  }
`
