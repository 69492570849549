import React, { useState, useEffect } from 'react'
import { navigate, graphql } from 'gatsby'
import { Seo, ContentWrapper } from '@cgtalks/common/components'
import Layout from '../molecules/layout'
import { OfflineBanner } from '../molecules/offline-banner'
import { SwiperSwitch } from '../molecules/main'
import { Pagination } from '@cgtalks/common/components/pagination'
import {
  Navigation,
  Category,
  CategoryItem,
  OfflineContent,
  OfflineTitle,
  Headline,
  OfflineShareContent,
  ShareOfflineContentCard,
  ShareCgOfflineEmptyContainer,
  RegistrationPoster,
  RenderPoster,
  PaginationWrapper,
} from './offline-activity.atom.js'
import { OfflineShareCard } from '../molecules/main'
import EmptyImg from '../images/online/empty.svg'
import { LocationService } from '@cgtalks/common/service'

const CategoryList = [
  { label: '全部', type: 'all' },
  { label: '报名中', type: 'signing' },
  { label: '已结束', type: 'end' },
]

const OfflineActivity = ({
  location,
  data: { signupPost, renderPost },
  pageContext: {
    sourceData,
    signingData,
    endregisterData,
    totalNum,
    signingTotalNum,
    endregisterTotalNum,
  },
}) => {
  const [category, setCategory] = useState(0)
  const [cardDataList, setCardDataList] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [numPages, setNumPages] = useState(1)
  function setCgOfflineCategory(item, index) {
    setCategory(index)
    setPageNum(1)
    const currentLocationUrl = LocationService.updateLocationSearchString({ page: 1 })
    navigate(currentLocationUrl, { state: { pageNum: 1, disableScrollToTop: true } })
    switch (item.type) {
      case 'all':
        setCardDataList([...sourceData.filter((_, itemIndex) => itemIndex >= 0 && itemIndex < 5)])
        setNumPages(Math.ceil(totalNum / 5))
        break
      case 'signing':
        setCardDataList([...signingData.filter((_, itemIndex) => itemIndex >= 0 && itemIndex < 5)])
        setNumPages(Math.ceil(signingTotalNum / 5))
        break
      case 'end':
        setCardDataList([
          ...endregisterData.filter((_, itemIndex) => itemIndex >= 0 && itemIndex < 5),
        ])
        setNumPages(Math.ceil(endregisterTotalNum / 5))
        break
      default:
        break
    }
  }
  useEffect(() => {
    switch (category) {
      case 0:
        setCardDataList([
          ...sourceData.filter(
            (_, itemIndex) => itemIndex >= (pageNum - 1) * 5 && itemIndex < pageNum * 5,
          ),
        ])
        setNumPages(Math.ceil(totalNum / 5))
        break
      case 1:
        setCardDataList([
          ...signingData.filter(
            (_, itemIndex) => itemIndex >= (pageNum - 1) * 5 && itemIndex < pageNum * 5,
          ),
        ])
        setNumPages(Math.ceil(signingTotalNum / 5))
        break
      case 2:
        setCardDataList([
          ...endregisterData.filter(
            (_, itemIndex) => itemIndex >= (pageNum - 1) * 5 && itemIndex < pageNum * 5,
          ),
        ])
        setNumPages(Math.ceil(endregisterTotalNum / 5))
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, category])

  useEffect(() => {
    if (location.search) {
      let current = location.search.replace('?page=', '')
      setPageNum(Number(current))
    }
  }, [location.search])

  return (
    <Layout location={location}>
      <Seo
        title='泛CG实用技术线下交流会-泛CG聚未来'
        description='Renderbus瑞云渲染主办的“泛CG聚未来”，不定时举办泛CG实用技术线下交流会,邀请行业内多位技术大牛与大家线下奔现,一起交流、分享CG技术。'
        keywords='cg技术交流会, CG技术线下交流,泛CG交流会'
        sharePostSlug='cgactivity/'
      />
      <ContentWrapper>
        <SwiperSwitch navBottom={40}>
          <OfflineBanner />
        </SwiperSwitch>
        <Navigation>
          <Category>
            {CategoryList.map((item, index) => (
              <CategoryItem
                key={index}
                active={category === index}
                onClick={() => setCgOfflineCategory(item, index)}
              >
                {item.label}
              </CategoryItem>
            ))}
          </Category>
        </Navigation>
        <OfflineContent>
          <OfflineTitle>
            <Headline>线下分享</Headline>
          </OfflineTitle>
          <OfflineShareContent>
            <div>
              {cardDataList && cardDataList.length ? (
                cardDataList.map((item, index) => {
                  return (
                    <ShareOfflineContentCard key={index}>
                      <OfflineShareCard info={item.node} />
                    </ShareOfflineContentCard>
                  )
                })
              ) : (
                <ShareCgOfflineEmptyContainer>
                  <img src={EmptyImg} alt='' />
                  <p>非常抱歉</p>
                  <p>没有找到相关内容</p>
                </ShareCgOfflineEmptyContainer>
              )}
              {numPages > 0 && (
                <PaginationWrapper>
                  <Pagination currentPage={pageNum} total={numPages} />
                </PaginationWrapper>
              )}
            </div>
            <div>
              <RegistrationPoster
                href={signupPost.nodes[0].link}
                target={signupPost.nodes[0].target}
              >
                <img src={signupPost.nodes[0].image[0]} alt='' />
              </RegistrationPoster>
              <RenderPoster href={renderPost.nodes[0].link} target={renderPost.nodes[0].target}>
                <img src={renderPost.nodes[0].image[0]} alt={renderPost.nodes[0].alt} />
              </RenderPoster>
            </div>
          </OfflineShareContent>
        </OfflineContent>
      </ContentWrapper>
    </Layout>
  )
}

export default OfflineActivity

export const query = graphql`
  query {
    signupPost: allContentfulOfflineSignupPost {
      nodes {
        image
        link
        target
      }
    }
    renderPost: allContentfulRenderPost {
      nodes {
        image
        link
        target
        alt
      }
    }
  }
`
