import styled from 'styled-components'
import { Media, color, mbSize } from '@cgtalks/common/theme'
import { H3 } from '@cgtalks/common/components'
import TimeSVG from '../../images/main/time.svg'
import AddressSVG from '../../images/main/address.svg'

export const IconTitleImg = styled.img`
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
`
export const IconTitleText = styled(H3)`
  position: absolute;
  top: -8px;
  text-align: center;
  width: 180px;
  font-size: 16px;
  line-height: 16px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(280)};
    font-size: ${mbSize(24)};
    line-height: ${mbSize(24)};
    top: ${mbSize(-12)};
  }
`
export const IconTitleWrapper = styled.div`
  ${Media.lessThan(Media.small)} {
    ${IconTitleImg} {
      width: ${(p) => mbSize(p.imgMbWidth)};
    }
    ${IconTitleText} {
      width: ${(p) => mbSize(p.imgMbWidth)};
      font-size: ${mbSize(24)};
      line-height: ${mbSize(24)};
      top: ${mbSize(-12)};
    }
  }
`

export const ShareOnlineContentCard = styled.div`
  width: 440px;
  height: 360px;
  background: #2b2b37;
  border-radius: 10px;
  position: relative;
  .online-share-title {
    padding: 25px 20px 12px 20px;
    color: #9b9baa;
    font-size: 14px;
    line-height: 14px;
  }
  .online-share-content {
    padding: 0 20px;
    display: flex;
    align-items: center;
    div {
      color: #fff;
      width: 268px;
      overflow: hidden;
      font-size: 16px;
      line-height: 28px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 设置两行文字溢出
      -webkit-box-orient: vertical;
    }
  }
  .gatsby-image-wrapper {
    height: 220px;
  }
  img {
    width: 440px;
    height: 220px;
    object-fit: cover;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(680)};
    height: max-content;
    padding-bottom: ${mbSize(34)};
    .gatsby-image-wrapper {
      height: ${mbSize(320)};
    }
    .online-share-title {
      line-height: ${mbSize(24)};
      font-size: ${mbSize(24)};
      padding: ${mbSize(40)} 0 ${mbSize(26)} ${mbSize(29)};
    }
    .online-share-content {
      font-size: ${mbSize(30)};
      padding: 0 0 0 ${mbSize(29)};
      div {
        font-size: ${mbSize(30)};
        line-height: ${mbSize(50)};
        width: ${mbSize(385)};
      }
    }
    img {
      height: 42.667vw;
      width: 100%;
    }
  }
`

export const LiveButton = styled.button`
  width: 100px;
  height: 36px;
  background: #00ffab;
  border-radius: 18px;
  color: #21252a;
  margin-left: 30px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: 0;
  &:hover {
    background: linear-gradient(-90deg, #0fd2b7 0%, #25cf6b 100%);
  }
  ${Media.lessThan(Media.small)} {
    margin-left: ${mbSize(49)};
    width: ${mbSize(200)};
    height: ${mbSize(70)};
  }
`

export const ShareOnlineCardStatus = styled.div`
  position: absolute;
  z-index: 1;
  color: ${(p) => p.color || '#1D1D25'};
  span {
    position: absolute;
    top: 5px;
    left: 14px;
    font-weight: bold;
    font-size: 14px;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(153.3)};
    height: ${mbSize(43.6)};
    svg {
      width: ${mbSize(153.3)};
      height: ${mbSize(43.6)};
    }
    span {
      font-size: ${mbSize(24)};
      left: ${mbSize(22)};
      top: unset;
      line-height: ${mbSize(43.6)};
    }
  }
`

export const GuestProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 67px !important;
  width: 264px;
  margin-right: 20px;
  align-items: center;
  color: #fff;
  &:last-child {
    margin-right: unset;
  }
  .img-wrapper {
    height: 214px;
    position: relative;
    text-align: center;
    margin-bottom: 35px;
    .avatar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 160px;
      height: 160px;
      border-radius: 50%;
    }
  }
  .name {
    font-weight: medium;
    font-size: 24px;
    line-height: 24px;
    color: ${color.primary};
  }
  .position {
    margin-top: 18px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 214px;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(20)} !important;
    margin-right: ${mbSize(20)};
    width: ${mbSize(264)};
    .img-wrapper {
      height: ${mbSize(214)};
      margin-bottom: ${mbSize(35)};
      img {
        height: ${mbSize(214)};
      }
      .avatar {
        width: ${mbSize(160)};
        height: ${mbSize(160)};
      }
    }
    .name {
      font-size: ${mbSize(30)};
      line-height: ${mbSize(30)};
    }
    .position {
      margin-top: ${mbSize(22)};
      font-size: ${mbSize(24)};
      line-height: ${mbSize(30)};
      width: ${mbSize(214)};
    }
  }
`

export const OfflineContentCard = styled.div`
  width: 920px;
  height: 300px;
  background: #2b2b37;
  border-radius: 4px;
  display: flex;
  img {
    width: 500px;
    height: 300px;
    object-fit: cover;
  }
  ${Media.lessThan(Media.small)} {
    width: 90.66vw;
    height: unset;
    border-radius: 0;
    display: block;
    img {
      width: 100%;
      height: 53.33vw;
    }
  }
`

export const ShareOfflineContent = styled.div`
  padding: 36px 35px 38px 30px;
  max-width: 420px;
  box-sizing: border-box;
  .offline-share-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 20px;
    font-family: Source Han Sans CN;
  }
  .offline-share-time,
  .offline-share-address {
    font-size: 14px;
    font-weight: 400;
    color: #c6c6d2;
    font-family: Source Han Sans CN;
    line-height: 27px;
    display: flex;
    align-items: baseline;
  }
  .offline-share-time {
    ::before {
      display: inline-block;
      content: '';
      background-image: ${`url(${TimeSVG})`};
      width: 16px;
      height: 16px;
      margin-right: 13px;
    }
  }
  .offline-share-address {
    margin-bottom: 30px;
    ::before {
      display: inline-block;
      content: '';
      width: 22px;
      height: 16px;
      background-image: ${`url(${AddressSVG})`};
      background-repeat: no-repeat;
      margin-right: 13px;
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 18px 18px 28px;
    max-width: unset;
    .offline-share-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .offline-share-time,
    .offline-share-address {
      line-height: 24px;
    }
    .offline-share-time {
      ::before {
        margin-right: 13px;
      }
    }
    .offline-share-address {
      margin-bottom: 20px;
    }
  }
`

export const MoreButton = styled.button`
  width: 140px;
  height: 36px;
  background: #00ffab;
  border-radius: 18px;
  color: #21252a;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: 0;
  &:hover {
    background: linear-gradient(-90deg, #0fd2b7 0%, #25cf6b 100%);
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(300)};
    height: ${mbSize(70)};
  }
`
