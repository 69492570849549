import { Billboard } from '@cgtalks/common/components'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BannerContainer, MainBannerTip } from './atoms'

const OfflineBanner = React.memo(function () {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/offline-banner/bg.png/" }) {
        ...fluidImage
      }
      bannerMobile: file(relativePath: { regex: "/banner/offline-banner/bg-mb.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <BannerContainer>
      <MainBannerTip>泛CG实用技术线下交流会</MainBannerTip>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMobile.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </BannerContainer>
  )
})

export default OfflineBanner
