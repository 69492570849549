export const LiveStatus = Object.freeze({
  NOTLIVE: 0, // 未直播
  LIVE: 1, // 直播中
  LIVEOVER: 2, // 直播结束
})

export const LiveStatusMap = new Map([
  [LiveStatus.NOTLIVE, { bgColor: '#FF7D22', text: '直播预告', color: '#FFFFFF' }],
  [LiveStatus.LIVE, { bgColor: '#F14C40', text: '正在直播', color: '#FFFFFF' }],
  [LiveStatus.LIVEOVER, { bgColor: '#00FFAB', text: '直播回放', color: '#1D1D25' }],
])

export const OfflineStatus = Object.freeze({
  OVER: 0, // 已结束报名
  SIGNING: 1, // 报名中
})

export const OfflineStatusMap = new Map([
  [OfflineStatus.OVER, { text: '结束报名', color: '#00FFAB' }],
  [OfflineStatus.SIGNING, { text: '报名中', color: '#F14C40' }],
])
